import { Button } from "@mui/material";

const TopupOptionBox = ({ item, optionOnClick, t }) => {
  return (
    <div className="bg-white p-6 shadow-md rounded-lg flex flex-col gap-4">
      <div className="flex flex-row gap-4">
        <div className="font-semibold text-lg">{item.name}</div>
      </div>
      <div className="font-light text-lg">{item.desc}</div>
      <div className="font-light text-lg">$ {item.price}</div>

      <div className="font-light text-lg">
        <span>{t("topup.received")}: </span>
        <span>{item?.num || 0}</span>
      </div>
      <Button variant="contained" onClick={() => optionOnClick(item._id)}>
        {t("topup.title")}
      </Button>
    </div>
  );
};

export default TopupOptionBox;
