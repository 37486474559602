import KeyValueBox from "../exercise/KeyValueBox";

const HistoryResultBox = ({ item, t }) => {
  return (
    <div className="bg-white shadow-md p-5 rounded-md flex flex-col gap-6">
      {/* <KeyValueBox label="Question" value={item.question} /> */}
      <KeyValueBox
        label={t("comp.your-ans")}
        label_key="comp.your-ans"
        value={item.answer}
        correct={item.correct}
      />
      <KeyValueBox
        label={t("comp.explanation")}
        label_key="comp.explanation"
        value={item.response}
      />
    </div>
  );
};

export default HistoryResultBox;
