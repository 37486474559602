import { TextField } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Controller } from "react-hook-form";

const AddVocabBox = ({ methods, name, remove, index, placeholder }) => {
  const { control } = methods;
  return (
    <div className="flex gap-6 items-center">
      <Controller
        name={`${name}[${index}].keyword`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            size="small"
            placeholder={placeholder}
            autoComplete="off"
            value={field.value || ""}
          />
        )}
      />
      <RemoveCircleOutlineIcon
        color="primary"
        className="cursor-pointer"
        onClick={() => {
          remove(index);
        }}
      />
    </div>
  );
};
export default AddVocabBox;
