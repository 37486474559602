import { useState, useEffect } from "react";
import ProfileInput from "../../components/profile/ProfileInput";
import PageTitle from "../../components/common/PageTitle";
import SubscriptionField from "../../components/profile/SubscriptionField";
import { Button } from "@mui/material";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../stores";
import LoadingOverlay from "../../components/common/LoadingOverlay";
import http from "../../utils/axios";
import TopupField from "../../components/profile/TopupField";
import { useTranslation } from "react-i18next";
import SwitchLanguageBtn from "../../components/common/SwitchLanguageBtn";

const Profile = ({}) => {
  const { t } = useTranslation("profile");
  const [isLoading, setIsLoading] = useState(true);
  const userStore = useUserStore();

  const navigate = useNavigate();

  const onUpdate = async (name, value) => {
    try {
      let payload = {};
      payload[name] = value;
      const res = await http.put(`/user/${userStore.user._id}`, payload);
      if (res.error === false) {
        userStore.getMe();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userStore.getMe().finally(() => {
      setIsLoading(false);
    });

    if (userStore.subscriptionOptions.length === 0) {
      userStore.getSubscriptionOptions();
    }
  }, [userStore?.user?.role]); // eslint-disable-line

  return (
    <div>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <PageTitle title={t("profile")} />
          <div className="bg-white p-8 shadow-md rounded-lg flex flex-col gap-8">
            <SwitchLanguageBtn
              value={userStore?.user?.lang || "zht"}
              onUpdate={onUpdate}
            />
            <ProfileInput
              label={t("name")}
              name="name"
              value={userStore?.user?.name}
              onUpdate={onUpdate}
              disabled
            />
            <ProfileInput
              label={t("email")}
              name="email"
              value={userStore?.user?.email}
              onUpdate={onUpdate}
              disabled
            />
            {userStore?.user?.role !== "admin" &&
            userStore?.user?.role !== "teacher" ? (
              <>
                <ProfileInput
                  label={t("school")}
                  name="school"
                  value={userStore?.user?.school}
                  onUpdate={onUpdate}
                />
                <ProfileInput
                  label={t("phone")}
                  name="phone"
                  value={userStore?.user?.phone}
                  onUpdate={onUpdate}
                />
                <SubscriptionField />
                <TopupField />
              </>
            ) : (
              <TopupField disabled={true} />
            )}
            <div className="h-4"></div>
            <div>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  navigate("/profile/creditHistory");
                }}
              >
                {t("credit-history")}
                <WysiwygIcon className="ml-2" />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Profile;
