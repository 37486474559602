import { useState, useEffect, useRef } from "react";
import PageTitle from "../../components/common/PageTitle";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box } from "@mui/material";
import { useUserStore, useShopStore } from "../../stores";
import http from "../../utils/axios";
import useToast from "../../utils/toast";
import { apiErrorMessage } from "../../utils/helpers";
import Modal from "../../components/common/Modal";
import { useTranslation } from "react-i18next";
import i18n from "../../config/i18n";
import { apiLocale } from "../../utils/definitions";

const MyCart = () => {
  const { t } = useTranslation("common");
  const lang = i18n.language;
  const modalRef = useRef();
  const userStore = useUserStore();
  const shopStore = useShopStore();
  const toast = useToast();

  const [totalPrice, setTotalPrice] = useState(0);
  const [rows, setRows] = useState([]);
  const [cartId, setCartId] = useState();

  const columns = [
    {
      field: "name",
      headerName: t("shop.name"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) => params?.row?.product?.name,
    },
    {
      field: "subject",
      headerName: t("shop.subject"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.row?.product?.subject[apiLocale[lang]] ||
        params?.row?.product?.subject?.name,
    },
    {
      field: "category",
      headerName: t("shop.category"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.row?.product?.category[apiLocale[lang]] ||
        params?.row?.product?.category?.name,
    },
    {
      field: "subcategory",
      headerName: t("shop.subcategory"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.row?.product?.subcategory[apiLocale[lang]] ||
        params?.row?.product?.subcategory?.name,
    },
    {
      field: "grade",
      headerName: t("shop.grade"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.row?.product?.grade[apiLocale[lang]] ||
        params?.row?.product?.grade?.name,
    },
    {
      field: "coin",
      headerName: t("shop.coin"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) => params?.row?.product?.coin,
    },
    {
      field: "actions",
      headerName: t("shop.action"),
      headerClassName: "bg-secondary",
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            removeItem(params.row._id, params.row.coin);
          }}
        >
          {t("cart.remove")}
        </Button>
      ),
    },
  ];

  const getMyCart = async () => {
    try {
      const res = await http.get("/cart", {
        user: userStore.user._id,
        limit: 99999,
      });
      if (res.error === false) {
        let total = 0;
        res.data[0]?.items?.forEach((i) => {
          total += i.product.coin;
        });
        setRows(res.data[0]?.items || []);
        setTotalPrice(total || 0);
        setCartId(res.data[0]?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem = async (id, coin) => {
    try {
      const newRows = rows.filter((row) => row._id !== id);
      const newTotal = totalPrice - coin;
      const payload = { items: newRows, total: newTotal };
      const res = await http.put(`/cart/${cartId}`, payload);
      if (res.error === false) {
        toast.success("Item removed");
        getMyCart();
      } else {
        throw res;
      }
    } catch (error) {
      toast.error(apiErrorMessage(error));
    }
  };

  const checkout = async () => {
    try {
      const res = await http.post("/order/checkout");
      if (res.error === false) {
        toast.success("Success! Your order has been placed.");
        shopStore.getPurchasedItem();
        userStore.getMe();
        await getMyCart();
      }
    } catch (error) {
      console.log(error);
      toast.error(apiErrorMessage(error));
    } finally {
      modalRef.current.handleClose();
    }
  };

  useEffect(() => {
    userStore.getMe();
    getMyCart();
  }, []); // eslint-disable-line

  return (
    <div>
      <PageTitle title={t("header.my-cart")} />
      <div className="text-end text-xl mb-2">
        <span className="font-light">{t("shop.coins")}:</span>
        {userStore.user?.coin || 0}
      </div>
      <div className="bg-white shadow-md rounded-md">
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          getRowId={(row) => row._id}
          disableSelectionOnClick
          hideFooterSelectedRowCount
          hideFooter
        />
      </div>
      <div className="flex justify-end mt-2">
        <div className="text-xl">
          <span>{t("cart.required")}: </span>
          <span>{totalPrice}</span>
        </div>
      </div>
      <div className="flex flex-col items-end gap-6 pt-6">
        <Button
          variant="contained"
          disabled={rows.length === 0}
          onClick={() => {
            modalRef.current.handleOpen();
          }}
        >
          {t("cart.proceed")}
        </Button>
      </div>

      <Modal ref={modalRef} title="Checkout confirmation">
        <Box>
          <Box sx={{ marginTop: 2, marginBottom: 4 }}>
            {t("cart.message1", { totalPrice })}
            <br />
            {t("cart.message2")}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                modalRef.current.handleClose();
              }}
            >
              {t("cart.cancel")}
            </Button>
            <Button variant="contained" onClick={checkout}>
              {t("cart.submit")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MyCart;
