import * as React from "react";
import MuiRadio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Controller } from "react-hook-form";
import i18n from "../../config/i18n";
import { apiLocale } from "../../utils/definitions";

export default function Radio({
  name,
  methods,
  label,
  options,
  required = false,
}) {
  const {
    control,
    formState: { errors },
  } = methods;

  return (
    <FormControl>
      <FormLabel>
        {label}
        {required && (
          <span
            style={
              errors[name]?.type === "required" ||
              errors[name]?.type === "optionality"
                ? { color: "red" }
                : { color: "black" }
            }
          >
            {" *"}
          </span>
        )}
      </FormLabel>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup row {...field} aria-required={required}>
            {options?.map((item) => (
              <FormControlLabel
                key={item._id}
                value={item._id}
                control={<MuiRadio />}
                label={item[apiLocale[i18n?.language]] || item.name}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
