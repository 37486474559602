import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import http from "../utils/axios";
import { useUserStore } from "../stores";
import { paginationObj } from "../utils/definitions";
import moment from "moment";
import { useTranslation } from "react-i18next";

import PageTitle from "../components/common/PageTitle";
import { Button, FormControl, Select, MenuItem } from "@mui/material";
import DataGrid from "../components/common/Table";
import { parseVocabHistory } from "../utils/helpers";

const History = () => {
  const { t } = useTranslation("exercise", "common");
  const userStore = useUserStore();
  const navigate = useNavigate();

  // ["comprehension", "vocab"]
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState("comprehension");
  const [pageObj, setPageObj] = useState(paginationObj);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const comprehensionCol = [
    {
      field: "createdAt",
      headerName: t("history.date"),
      flex: 2,
      headerClassName: headerClassName,
      valueGetter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm a"),
    },
    {
      field: "article",
      headerName: t("history.article"),
      flex: 3,
      headerClassName: headerClassName,
    },
    {
      field: "score",
      headerName: t("history.marks"),
      flex: 1,
      headerClassName: headerClassName,
      renderCell: (params) =>
        params?.row?.score
          ? `${params?.row?.score}/${params?.row?.total}`
          : "0",
    },
    {
      field: "perc",
      headerName: t("history.marksPerc"),
      flex: 1,
      headerClassName: headerClassName,
      valueGetter: (params) =>
        params?.value ? Math.floor(params?.value) + "%" : "0",
    },
    {
      field: "actions",
      headerName: t("history.action"),
      minWidth: 150,
      headerClassName: headerClassName,
      sortable: false,
      renderCell: (params) => (
        <div className="flex justify-between w-full">
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/history/detail/${params?.row?._id}`);
            }}
          >
            {t("history.view")}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/exercise/comprehension/${params?.row?._id}/redo`);
            }}
          >
            {t("history.redo")}
          </Button>
        </div>
      ),
    },
  ];

  const grammarCol = [
    {
      field: "createdAt",
      headerName: t("history.date"),
      flex: 2,
      headerClassName: headerClassName,
      valueGetter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm a"),
    },
    {
      field: "article",
      headerName: t("history.article"),
      flex: 3,
      headerClassName: headerClassName,
    },
    {
      field: "score",
      headerName: t("history.marks"),
      flex: 1,
      headerClassName: headerClassName,
      renderCell: (params) =>
        params?.row?.score
          ? `${params?.row?.score}/${params?.row?.total}`
          : "0",
    },
    {
      field: "perc",
      headerName: t("history.marksPerc"),
      flex: 1,
      headerClassName: headerClassName,
      valueGetter: (params) =>
        params?.value ? Math.floor(params?.value) + "%" : "0",
    },
    {
      field: "actions",
      headerName: t("history.action"),
      minWidth: 150,
      headerClassName: headerClassName,
      sortable: false,
      renderCell: (params) => (
        <div className="flex justify-between w-full">
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/history/detail/${params?.row?._id}`);
            }}
          >
            {t("history.view")}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/exercise/grammar/${params?.row?._id}/redo`);
            }}
          >
            {t("history.redo")}
          </Button>
        </div>
      ),
    },
  ];

  // need to init comprehensionCol first
  const [columns, setColumns] = useState(comprehensionCol);

  const vocabCol = [
    {
      field: "createdAt",
      headerName: t("history.date"),
      flex: 2,
      headerClassName: headerClassName,
      valueGetter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm a"),
    },
    {
      field: "qa",
      headerName: t("history.vocabularies"),
      flex: 3,
      headerClassName: headerClassName,
      renderCell: (params) => {
        return parseVocabHistory(params.value);
      },
    },
    {
      field: "score",
      headerName: t("history.marks"),
      flex: 1,
      headerClassName: headerClassName,
      renderCell: (params) =>
        params?.row?.score
          ? `${params?.row?.score}/${params?.row?.total}`
          : "0",
    },
    {
      field: "perc",
      headerName: t("history.marksPerc"),
      flex: 1,
      headerClassName: headerClassName,
      valueGetter: (params) =>
        params?.value ? Math.floor(params?.value) + "%" : "0",
    },
    {
      field: "actions",
      headerName: t("history.action"),
      minWidth: 150,
      headerClassName: headerClassName,
      sortable: false,
      renderCell: (params) => (
        <div className="flex justify-between w-full">
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/exercise/vocabulary/${params?.row?._id}`);
            }}
          >
            {t("history.view")}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/exercise/vocabulary/${params?.row?._id}/redo`);
            }}
          >
            {t("history.redo")}
          </Button>
        </div>
      ),
    },
  ];

  const changeTab = (e) => {
    if (e.target.value !== tab) {
      setIsLoading(true);

      switch (e.target.value) {
        case "comprehension":
          setColumns(comprehensionCol);
          break;
        case "vocab":
          setColumns(vocabCol);
          break;
        case "grammar":
          setColumns(grammarCol);
          break;
        default:
          setColumns(comprehensionCol);
      }

      setPageObj({ ...paginationObj });
    }

    setTab(e.target.value);
  };

  const getHistory = async () => {
    try {
      const res = await http.get("/attempt", {
        user: userStore.user._id,
        type: tab,
        ...pageObj,
      });
      setRows(res.data);
      setTotal(res.total);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userStore.user._id) {
      getHistory();
    }
  }, [pageObj, userStore.user]); // eslint-disable-line

  return (
    <div>
      <PageTitle title={t("header.performance", { ns: "common" })}>
        <FormControl>
          <Select
            sx={{ backgroundColor: "white" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={tab}
            onChange={changeTab}
          >
            <MenuItem value={"comprehension"}>{t("history.comp")}</MenuItem>
            <MenuItem value={"grammar"}>{t("history.grammar")}</MenuItem>
            <MenuItem value={"vocab"}>{t("history.vocab")}</MenuItem>
          </Select>
        </FormControl>
      </PageTitle>
      <div className="">
        <DataGrid
          loading={isLoading}
          rows={rows}
          columns={columns}
          limit={pageObj.limit}
          page={pageObj.page}
          rowCount={total}
          onSortModelChange={(model) => {
            setPageObj((prev) => ({
              ...prev,
              sort_by: model[0]?.field,
              order_by: model[0]?.sort,
            }));
          }}
          onPaginationModelChange={(model) => {
            setPageObj((prev) => ({
              ...prev,
              page: model?.page + 1,
              limit: model?.pageSize,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default History;

const headerClassName = "bg-secondary";
