import { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useToast from "../utils/toast";
import http from "../utils/axios";
import { apiErrorMessage, vocabParser } from "../utils/helpers";
import { useExerciseStore, useUserStore } from "../stores";
import { useTranslation } from "react-i18next";
import i18n from "../config/i18n";

import { Button, CircularProgress } from "@mui/material";
import PageTitle from "../components/common/PageTitle";
import AddVocabBox from "../components/vocab/AddVocabBox";
import NoSubscription from "./common/NoSubscription";
import moment from "moment";
import LevelSelection from "../components/vocab/LevelSelection";
import { MonetizationOnOutlined } from "@mui/icons-material";

import { VideoModal } from "../components/common/VideoModal";
import { PlayCircle } from "@mui/icons-material";

const Vocabulary = () => {
  const { t } = useTranslation(["exercise", "common"]);
  const exerciseStore = useExerciseStore();
  const userStore = useUserStore();
  const [canView, setCanView] = useState(true);

  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const methods = useForm({
    defaultValues: {
      vocabularies: [{ keyword: "" }],
      level: 2,
    },
  });
  const { control, handleSubmit } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "vocabularies",
  });

  useEffect(() => {
    if (
      userStore?.user?.role === "admin" ||
      userStore?.user?.role === "teacher" ||
      userStore?.user?.bypass
    ) {
      setCanView(true);
    } else {
      // if subscription end is less than today
      if (
        !userStore?.user?.subscription?.end ||
        moment(userStore?.user?.subscription?.end).isBefore(moment())
      ) {
        setCanView(false);
      } else {
        setCanView(true);
      }
    }
  }, [userStore?.user?.subscription?.end, userStore?.user?.role]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      let formError = false;
      const words = data.vocabularies.map((item) => {
        if (item.keyword === "") {
          formError = true;
        }
        return item.keyword;
      });
      if (formError) {
        toast.error("Please fill all the fields");
        return;
      }
      const aiRes = await http.post("/ai/vocab", {
        words,
        generate_level: data.level,
      });

      const {
        questions,
        words: correctedWords,
        answers,
      } = vocabParser(aiRes.choices[0].message.content);

      exerciseStore.setVocabQuestion(questions);
      exerciseStore.setVocabOptions(correctedWords);
      exerciseStore.setVocabAnswers(answers);
      navigate(`/exercise/vocabulary`);
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <VideoModal
        isOpen={open}
        onClose={() => setOpen(false)}
        videoId="J71v7-u9dAI"
      />
      <NoSubscription visible={!canView} />
      <PageTitle title={t("header.vocab", { ns: "common" })}>
        <Button
          onClick={() => setOpen(true)}
          className="text-2xl md:text-4xl pt-0"
        >
          <PlayCircle sx={{ fontSize: 36 }} />{" "}
          {t("common:header.tutorial-clip")}
        </Button>
      </PageTitle>
      <div className="bg-white shadow-md p-8">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="font-semibold text-primary">
            {t("vocab.max-vocab")}
          </div>
          {fields.map((item, index) => (
            <AddVocabBox
              key={item.id}
              index={index}
              remove={remove}
              methods={methods}
              name="vocabularies"
              placeholder={t("vocab.input-vocab")}
            />
          ))}
          <div>
            {fields.length < 10 && (
              <Button
                type="button"
                onClick={() =>
                  append({
                    keyword: "",
                  })
                }
                variant="outlined"
              >
                {t("vocab.add")}
              </Button>
            )}
          </div>
          <div className="flex justify-end">
            <div className="flex flex-col items-end gap-4">
              <LevelSelection name="level" methods={methods} i18n={i18n} />
              <div>
                <Button
                  variant="contained"
                  type="submit"
                  className="relative"
                  disabled={fields.length === 0 || isLoading}
                >
                  <MonetizationOnOutlined />
                  &nbsp;{t("vocab.continue")}
                  {isLoading && (
                    <CircularProgress className="absolute" size={20} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Vocabulary;
