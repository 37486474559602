import { TextField } from "@mui/material";

const ResultsArticle = ({ article = "", label }) => {
  return (
    <div className="bg-white px-6 py-8 rounded-md mb-8 shadow-md flex flex-col items-start gap-1">
      <label className="font-medium text-lg">{label}</label>
      <TextField
        sx={{
          "& .Mui-disabled": {
            WebkitTextFillColor: "black !important",
          },
        }}
        disabled
        value={article}
        multiline
        type="text"
        size="small"
        fullWidth
        maxRows={10}
      ></TextField>
    </div>
  );
};

export default ResultsArticle;
