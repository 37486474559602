import {
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiLocale } from "../../utils/definitions";

const Filter = ({ item, filtersOnChanged, lang }) => {
  return (
    <Accordion className="">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {item.label[lang]}
      </AccordionSummary>
      <AccordionDetails>
        <FormGroup>
          {item.options.map((option) => (
            <FormControlLabel
              key={option._id}
              label={option[apiLocale[lang]] || option.name}
              className="ml-2"
              control={
                <Checkbox
                  checked={option.checked}
                  onChange={() =>
                    filtersOnChanged({ id: option._id, type: item.type })
                  }
                  name={option.name}
                />
              }
            />
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default Filter;
