import { useState, useEffect } from "react";
import PageTitle from "../../components/common/PageTitle";
import { useUserStore } from "../../stores";
import http from "../../utils/axios";
import DataGrid from "../../components/common/Table";
import { paginationObj } from "../../utils/definitions";
import moment from "moment";
import { useTranslation } from "react-i18next";

const CreditHistory = () => {
  const { t } = useTranslation("common");
  const userStore = useUserStore();
  const [rows, setRows] = useState([]);
  const [pageObj, setPageObj] = useState(paginationObj);
  const [total, setTotal] = useState(0);

  const columns = [
    {
      field: "createdAt",
      headerName: t("credit.date"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        moment(params?.value).format("DD/MM/YYYY HH:mm a"),
    },
    {
      field: "type",
      headerName: t("credit.type"),
      flex: 1,
      headerClassName: "bg-secondary",
    },
    {
      field: "change",
      headerName: t("credit.change"),
      flex: 1,
      headerClassName: "bg-secondary",
    },
    {
      field: "remain",
      headerName: t("credit.balance"),
      flex: 1,
      headerClassName: "bg-secondary",
      sortable: false,
    },
  ];

  const getRecords = async () => {
    console.log(userStore.user._id);
    try {
      const res = await http.get("/log-coin", {
        user: userStore.user._id,
        ...pageObj,
      });
      setRows(res.data);
      setTotal(res.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userStore.user._id) {
      getRecords();
    }
  }, [pageObj, userStore.user]); // eslint-disable-line

  return (
    <div className="">
      <PageTitle title={t("credit.title")} />
      <div className="">
        <DataGrid
          rows={rows}
          columns={columns}
          limit={pageObj.limit}
          page={pageObj.page}
          rowCount={total}
          onSortModelChange={(model) => {
            setPageObj((prev) => ({
              ...prev,
              sort_by: model[0]?.field,
              order_by: model[0]?.sort,
            }));
          }}
          onPaginationModelChange={(model) => {
            setPageObj((prev) => ({
              ...prev,
              page: model?.page + 1,
              limit: model?.pageSize,
            }));
          }}
        />
      </div>
    </div>
  );
};

export default CreditHistory;
