import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import http from "../../utils/axios";
import useToast from "../../utils/toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import ResultBox from "./ResultBox";
import { Button } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DisplayScore from "./DisplaySocre";
import ResultsArticle from "./ResultsArticle";
import {
  formAiPayload,
  formFurtherQuestionPayload,
  apiErrorMessage,
} from "../../utils/helpers";
import GenerateNewButton from "./GenerateNewButton";
import PageTitle from "../common/PageTitle";

const Results = () => {
  const { t } = useTranslation("exercise");
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [attempt, setAttempt] = useState();
  const [aiPayload, setAiPayload] = useState({});
  const { attemptId } = useParams();

  const isGrammar = location.pathname.includes("grammar");

  const getAttempt = async () => {
    try {
      const res = await http.get(`/attempt/${attemptId}`);
      if (res.error === false) {
        setAttempt(res.item);
        setAiPayload(formFurtherQuestionPayload(res.item));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const generateNewExercise = async (level) => {
    try {
      setIsLoading(true);
      const paperRes = await http.get(`/paper/${attempt.paper._id}`);
      const aiPayload = {
        ...formAiPayload(attempt),
        grade: paperRes?.item?.grade._id,
        subject: paperRes?.item?.subject._id,
        generate_level: level,
      };
      const aiRes = isGrammar
        ? await http.post("/ai/grammar", aiPayload)
        : await http.post("/ai/comprehension", aiPayload);

      if (isGrammar) {
        navigate(`/exercise/grammar/${aiRes?.paper?.id}`);
      } else {
        navigate(`/exercise/comprehension/${aiRes?.paper?.id}`);
      }
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getAttempt();
  }, []); // eslint-disable-line

  return (
    <div>
      {isGrammar ? (
        <PageTitle title={t("header.grammar", { ns: "common" })}></PageTitle>
      ) : (
        <PageTitle title={t("header.reading", { ns: "common" })}></PageTitle>
      )}
      <DisplayScore
        score={attempt?.score}
        total={attempt?.total}
        perc={attempt?.perc}
        label={t("comp.score")}
      />
      <ResultsArticle article={attempt?.article} label={t("comp.article")} />
      <div className="flex flex-col gap-8">
        {attempt?.qa?.map((item) => (
          <ResultBox
            item={item}
            key={item._id}
            aiPayload={aiPayload}
            attempt={attempt}
            getAttempt={getAttempt}
            t={t}
          />
        ))}
      </div>
      <div className="flex flex-row justify-end mt-8 gap-8">
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/exercise");
          }}
        >
          {t("comp.exit")}
          <ExitToAppIcon className="ml-2" />
        </Button>
        <GenerateNewButton
          label={t("comp.generate-new")}
          generateNewFunc={generateNewExercise}
          isLoading={isLoading}
          t={t}
        />
      </div>
    </div>
  );
};

export default Results;
