import React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const QuestionSection = ({
  nameMain,
  methods,
  index,
  placeholder = "",
  remove,
}) => {
  const { t } = useTranslation("exercise");
  const { control } = methods;

  return (
    <div className="bg-white px-6 py-8 rounded-md flex flex-col gap-4 shadow-md">
      <div className="flex flex-row justify-between">
        {/* <div className="font-medium text-xl">{t("comp.question")}</div> */}
        <div className="font-medium text-xl">
          {t("comp.answer")} {index + 1}
        </div>
        <div
          className="text-gray-500 cursor-pointer"
          onClick={() => {
            remove(index);
          }}
        >
          <DeleteOutlineIcon />
        </div>
      </div>
      {/* <Controller
        name={`${nameMain}[${index}].question`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            multiline
            minRows={2}
            size="small"
            fullWidth
            placeholder={placeholder}
            autoComplete="off"
            value={field.value || ""}
          />
        )}
      /> */}
      <Controller
        name={`${nameMain}[${index}].answer`}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            multiline
            minRows={2}
            size="small"
            fullWidth
            placeholder={placeholder}
            autoComplete="off"
            value={field.value || ""}
          />
        )}
      />
    </div>
  );
};

export default QuestionSection;
