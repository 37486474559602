import { useState, useEffect } from "react";
import PageTitle from "../../components/common/PageTitle";
import http from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import TopupOptionBox from "../../components/topop/TopupOptionBox";
import { useTranslation } from "react-i18next";

const TopUp = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const [optioins, setOptions] = useState([]);

  const getTopUpOptions = async () => {
    try {
      const res = await http.get("/coin");
      setOptions(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTopUpOptions();
  }, []);

  const optionOnClick = (id) => {
    navigate(`/topup/${id}/payment`);
  };

  return (
    <div>
      <PageTitle title={t("topup.title")} />
      <div className="flex flex-col gap-6">
        {optioins.map((item, index) => (
          <TopupOptionBox
            key={index}
            item={item}
            optionOnClick={optionOnClick}
            t={t}
          />
        ))}
      </div>
    </div>
  );
};

export default TopUp;
