import { TextField } from "@mui/material";

const KeyValueBox = ({ label, value, correct, label_key }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="font-medium text-lg">{label}</div>
      <div className={label === "Suggested answer" ? "" : ""}>
        {label !== "Question" ? (
          <TextField
            value={value}
            disabled
            fullWidth
            multiline
            maxRows={5}
            type="text"
            size="small"
            sx={
              label_key === "comp.your-ans"
                ? {
                    "& .Mui-disabled": {
                      WebkitTextFillColor: "black !important",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "2px",
                      borderColor: correct
                        ? "#2ECC71 !important"
                        : "#E74C3C !important",
                    },
                  }
                : {
                    "& .Mui-disabled": {
                      WebkitTextFillColor: "black !important",
                    },
                  }
            }
          />
        ) : (
          <div>{value}</div>
        )}
      </div>
    </div>
  );
};

export default KeyValueBox;
