import { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../stores";
import { useStripe } from "@stripe/react-stripe-js";
import http from "../../utils/axios";
import { useTranslation } from "react-i18next";

const SubscriptionField = () => {
  const navigate = useNavigate();
  const userStore = useUserStore();

  const [subscription, setSubscription] = useState("");

  const stripe = useStripe();

  const { t } = useTranslation("profile");

  useEffect(() => {
    setSubscription(() => {
      if (!userStore.user?.subscription?.start) {
        return "";
      }

      const plan = userStore.subscriptionOptions.find(
        (option) => option._id === userStore.user?.subscription?.subscription,
      );
      setSubscription(plan?.name || "N/A");
    });
  }, [
    userStore?.user?.subscription?.start,
    userStore?.user?.subscription?.subscription,
    userStore?.subscriptionOptions,
  ]); // eslint-disable-line

  if (!stripe) {
    return "";
  }

  const checkoutFunc = async (id) => {
    try {
      const res = await http.post(
        "payment/stripe/subscription/changePaymentMethod",
      );
      stripe.redirectToCheckout({ sessionId: res.sessionId });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex gap-4 flex-col">
      <div className="font-semibold">{t("subscription")}</div>
      <div className="flex gap-6">
        <TextField
          value={subscription || "N/A"}
          disabled
          variant="standard"
          sx={{
            minWidth: "300px",
            "& .Mui-disabled.MuiInputBase-input": {
              WebkitTextFillColor: "#7c7c7c",
            },
          }}
        />
        <Button
          variant="contained"
          color="info"
          onClick={() => {
            navigate("/profile/subscription");
          }}
        >
          {t("view")}
        </Button>

        {userStore.user?.subscription?.start && (
          <Button
            variant="contained"
            color="info"
            onClick={() => {
              checkoutFunc();
            }}
          >
            {t("change-payment-method")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionField;
