import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Button, CircularProgress, Hidden } from "@mui/material";
import http from "../../utils/axios";
import { useShopStore } from "../../stores";
import Radio from "../form/Radio";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MonetizationOnOutlined } from "@mui/icons-material";

const UploadPdf = ({ type }) => {
  const { t } = useTranslation("exercise");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const shopStore = useShopStore();

  const methods = useForm({
    defaultValues: { file: null, subject: null, grade: null },
  });
  const { handleSubmit, setValue, watch } = methods;
  // to validate input
  const watchSubject = watch("subject");
  const watchGrade = watch("grade");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const res = await http.postFiles("/ocr/upload", data);
      if (res.error === false) {
        if (type === "grammar") {
          navigate(`/exercise/grammar/${res.paper._id}`);
        } else {
          navigate(`/exercise/comprehension/${res.paper._id}`);
        }
      } else {
        throw res;
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setValue("file", acceptedFiles[0]);
    }
  }, [acceptedFiles, setValue]);

  return (
    <section className="bg-white p-6 shadow-md">
      <div className="font-semibold mb-6 ">{t("comp.upload-file")}:</div>
      <div
        {...getRootProps({
          className:
            "flex justify-center items-center cursor-pointer border-2 border-dotted h-48",
        })}
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center">
          <div>
            <UploadFileIcon fontSize="large" color="primary" />
          </div>
          <div>{t("comp.drop-here")}</div>
          <div className="font-extralight text-sm">{t("comp.only-pdf")}</div>
        </div>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <Radio
          required
          label={t("comp.subject")}
          name="subject"
          methods={methods}
          options={shopStore.subjects}
        />
        <Radio
          required
          label={t("comp.grade")}
          name="grade"
          methods={methods}
          options={shopStore.grades}
        />
        <Hidden name="type" methods={methods} value={type} />
        <div className="flex justify-end">
          <Button
            variant="contained"
            disabled={
              acceptedFiles.length === 0 ||
              !watchSubject ||
              !watchGrade ||
              isLoading
            }
            className="relative"
            type="submit"
          >
            <MonetizationOnOutlined />
            &nbsp;{t("comp.upload")}
            {isLoading && <CircularProgress className="absolute" size={20} />}
          </Button>
        </div>
      </form>
    </section>
  );
};

export default UploadPdf;
