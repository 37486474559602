import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

const AnswerBox = ({ item, methods, name, index, showResult }) => {
  const { control } = methods;
  const parts = item?.question?.split("_");

  return (
    <div>
      {parts.map((part, partIndex) => (
        <React.Fragment key={partIndex}>
          {part}
          {partIndex !== parts.length - 1 && (
            <Controller
              name={`${name}[${index}].answer`}
              control={control}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    className="px-2"
                    sx={
                      showResult && item?.correct
                        ? {
                            "& .Mui-disabled": {
                              WebkitTextFillColor: "#22c55e !important",
                            },
                          }
                        : showResult && !item?.correct
                          ? {
                              "& .Mui-disabled": {
                                WebkitTextFillColor: "red !important",
                              },
                            }
                          : {}
                    }
                    size="small"
                    autoComplete="off"
                    variant="standard"
                    //value={field.value || ""}
                    disabled={showResult}
                  />
                  {showResult && !item?.correct && (
                    <span className="text-green-500">({item.response})</span>
                  )}
                </>
              )}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AnswerBox;
