import { useNavigate } from "react-router-dom";
import { Button, Box } from "@mui/material";
import PageTitle from "../../components/common/PageTitle";
import { useTranslation } from "react-i18next";

const NoSubscription = ({ visible = false }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        position: "fixed",
        top: 98,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 0.2)",
        backdropFilter: "blur(4px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 100,
        visibility: visible ? "visible" : "hidden",
      }}
    >
      <>
        <PageTitle title={t("subscribe-to-use")} />
        <Button
          onClick={() => navigate("/profile/subscription")}
          variant="contained"
        >
          {t("subscribe-now")}
        </Button>
      </>
    </Box>
  );
};

export default NoSubscription;
