import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { CircularProgress } from "@mui/material";
import { MonetizationOnOutlined } from "@mui/icons-material";

export default function GenerateNewButton({
  generateNewFunc,
  isLoading,
  label,
  t,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (level) => {
    generateNewFunc(level);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="contained"
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="relative"
        onClick={handleClick}
        disabled={isLoading}
      >
        <MonetizationOnOutlined />
        &nbsp;{label}
        <AutorenewIcon className="ml-2" />
        {isLoading && <CircularProgress className="absolute" size={20} />}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          // menu item width
          sx: { width: anchorEl && anchorEl.offsetWidth },
        }}
      >
        {/*  1 easier, 2 similar, 3 harder  */}
        <MenuItem onClick={() => handleClose(1)}>{t("comp.easier")}</MenuItem>
        <MenuItem onClick={() => handleClose(2)}>{t("comp.similar")}</MenuItem>
        <MenuItem onClick={() => handleClose(3)}>{t("comp.harder")}</MenuItem>
      </Menu>
    </div>
  );
}
