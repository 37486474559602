import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from "yup";
import http from "../../utils/axios";
import useToast from "../../utils/toast";
import { setLocalStorage, apiErrorMessage } from "../../utils/helpers";
import { useTranslation } from "react-i18next";

import useYupValidationResolver from "../../utils/yup";
import Layout from "../../components/common/Layout";
import { Button } from "@mui/material";
import FormInput from "../../components/form/Input";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const ResetPassword = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  // get token from url
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  // validation form
  const validationSchema = yup.object({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm({
    resolver,
  });

  const [capVal, setCapVal] = useState(null);

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      const res = await http.post("/forgot-password/reset", {
        password: data.password,
        token,
      });
      if (res?.message) {
        // alert
        alert(t("login.reset-succeeded"));
        navigate("/login");
      }
    } catch (error) {
      const errorMessage = apiErrorMessage(error);
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Unknown Error");
      }
    }
  };

  return (
    <Layout>
      <div className="flex justify-center w-full">
        <div className="bg-white shadow-md rounded-md p-4 w-full sm:py-12 sm:px-16 sm:my-16 sm:w-3/5">
          <div>
            <Button
              className="p-0 capitalize"
              onClick={() => navigate("/login")}
            >
              <ChevronLeftIcon className="mr-2" />
              {t("back")}
            </Button>
          </div>
          <div className="text-center">
            <div className="text-3xl mb-2 capitalize">
              {t("login.reset-password")}
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <FormInput
              type="password"
              methods={methods}
              name="password"
              label={t("password")}
              required
            />
            <FormInput
              type="password"
              methods={methods}
              name="confirmPassword"
              label={t("login.confirm-password")}
              required
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={(value) => setCapVal(value)}
            />
            <div className="flex flex-col">
              <Button
                variant="contained"
                type="submit"
                disabled={!capVal}
                className="capitalize"
              >
                {t("login.submit")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
