import { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";

const OcrResultBox = ({ rawText, label }) => {
  const buttonRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // default close
    // setAnchorEl(buttonRef.current);
  }, []);

  return (
    <div>
      <Button
        ref={buttonRef}
        aria-describedby={id}
        variant="contained"
        color="info"
        className="fixed top-28 right-28 z-50"
        onClick={handleClick}
      >
        {label}
      </Button>
      <Popover
        id={id}
        disableScrollLock
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2, whiteSpace: "pre-line" }} component="div">
          {rawText}
        </Typography>
      </Popover>
    </div>
  );
};
export default OcrResultBox;
