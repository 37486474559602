import { DataGrid as MuiDataGrid } from "@mui/x-data-grid";

const DataGrid = ({ rows, columns, page, limit, ...props }) => {
  console.log(rows.map((row) => row._id || row.name));
  console.log(props);

  return (
    <MuiDataGrid
      className="min-h-full"
      autoHeight
      rows={rows}
      columns={columns}
      getRowId={(row) => row._id || row.name}
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      pageSizeOptions={[10, 20, 50]}
      filterMode="server"
      paginationMode="server"
      disableColumnMenu
      disableColumnFilter
      paginationModel={{
        page: page - 1,
        pageSize: limit,
      }}
      {...props}
    />
  );
};

export default DataGrid;
