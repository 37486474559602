import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import http from "../utils/axios";
import { useTranslation } from "react-i18next";

import PageTitle from "../components/common/PageTitle";
import HistoryResultBox from "../components/history/HistoryResultBox";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DisplayScore from "../components/exercise/DisplaySocre";
import ResultsArticle from "../components/exercise/ResultsArticle";

const HistoryDetail = () => {
  const { t } = useTranslation(["exercise", "common"]);
  const navigate = useNavigate();
  const [attempt, setAttempt] = useState();
  const { attemptId } = useParams();

  const getAttempt = async () => {
    try {
      const res = await http.get(`/attempt/${attemptId}`);
      if (res.error === false) {
        setAttempt(res.item);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttempt();
  }, []);

  return (
    <div>
      <PageTitle title={t("header.performance", { ns: "common" })} />
      <div className="flex flex-row mt-8">
        <Button
          className="capitalize"
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon className="mr-2" />
          {t("back", { ns: "common" })}
        </Button>
      </div>
      <DisplayScore
        score={attempt?.score}
        total={attempt?.total}
        perc={attempt?.perc}
        label={t("comp.score")}
      />
      <ResultsArticle article={attempt?.article} label={t("comp.article")} />
      <div className="flex flex-col gap-8">
        {attempt?.qa?.map((item) => (
          <HistoryResultBox item={item} key={item._id} t={t} />
        ))}
      </div>
    </div>
  );
};

export default HistoryDetail;
