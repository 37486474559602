import { useState, useEffect } from "react";
import { Button, CircularProgress } from "@mui/material";
import http from "../utils/axios";
import PageTitle from "../components/common/PageTitle";
import Filter from "../components/shop/Filter";
import { useShopStore, useUserStore } from "../stores";
import { paginationObj, apiLocale } from "../utils/definitions";
import DataGrid from "../components/common/Table";
import useToast from "../utils/toast";
import { apiErrorMessage } from "../utils/helpers";
import { useTranslation } from "react-i18next";
import i18n from "../config/i18n";

const Shop = () => {
  const { t } = useTranslation("common");
  const lang = i18n.language;
  const shopStore = useShopStore((state) => state);
  const userStore = useUserStore();
  const toast = useToast();

  const [pageObj, setPageObj] = useState(paginationObj);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);

  const filtersOnChanged = ({ id, type }) => {
    setPageObj((prev) => {
      if (!prev[type]) {
        prev[type] = [id];
      } else {
        if (prev[type].includes(id)) {
          prev[type] = prev[type].filter((item) => item !== id);
        } else {
          prev[type].push(id);
        }
      }
      return { ...prev };
    });
  };

  const addToCart = async (id, coin) => {
    try {
      const myCartRes = await http.get("/cart", { user: userStore.user._id });
      let myCart = [];
      if (myCartRes?.data[0]?.items?.some((i) => i.product._id === id)) {
        toast.success("Already added");
        return;
      }

      let addCartResponse;
      if (myCartRes?.data?.length > 0) {
        myCart = [...myCartRes.data[0].items, { product: id }];
        addCartResponse = await http.put(`/cart/${myCartRes.data[0]._id}`, {
          items: myCart,
        });
      } else {
        myCart.push({ product: id });
        addCartResponse = await http.post("/cart", { items: myCart });
      }
      if (addCartResponse.error === false) {
        toast.success("Added to cart");
      } else {
        throw addCartResponse;
      }
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    }
  };

  const downloadItem = async (id, fileName) => {
    try {
      const res = await http.get(`/product/download/${id}`, undefined, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: t("shop.name"),
      flex: 1,
      headerClassName: "bg-secondary",
    },
    {
      field: "subject",
      headerName: t("shop.subject"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.value[apiLocale[lang]] || params.value?.name,
    },
    {
      field: "category",
      headerName: t("shop.category"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.value[apiLocale[lang]] || params.value?.name,
    },
    {
      field: "subcategory",
      headerName: t("shop.subcategory"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.value[apiLocale[lang]] || params.value?.name,
    },
    {
      field: "grade",
      headerName: t("shop.grade"),
      flex: 1,
      headerClassName: "bg-secondary",
      valueGetter: (params) =>
        params?.value[apiLocale[lang]] || params.value?.name,
    },
    {
      field: "coin",
      headerName: t("shop.coin"),
      flex: 1,
      headerClassName: "bg-secondary",
    },
    {
      field: "actions",
      headerName: t("shop.action"),
      headerClassName: "bg-secondary",
      renderCell: (params) => {
        return (
          <>
            {shopStore.purchasedItems.includes(params.row?._id) ? (
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  downloadItem(params.row._id, params.row.file);
                }}
              >
                {t("shop.download")}
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={() => addToCart(params?.row?._id, params.row.coin)}
              >
                {t("shop.add-cart")}
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const getProduct = async () => {
    try {
      const res = await http.get("/product", pageObj);
      if (res.error === false) {
        setRows(res.data);
        setTotal(res.total);
      } else {
        throw res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    userStore.getMe();
    if (!shopStore.fetched) {
      shopStore.getAll();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    getProduct();
  }, [pageObj]); // eslint-disable-line

  return (
    <div>
      <PageTitle title={t("header.buy-exercise")}></PageTitle>
      <div className=" mt-8 md:grid md:grid-flow-col flex flex-col gap-8">
        {!shopStore.fetched ? (
          <div className="max-w-full md:max-w-72 col-span-1 min-w-40 flex justify-center items-center bg-white rounded-md shadow-md">
            <CircularProgress />
          </div>
        ) : (
          <div className="max-w-full md:max-w-72 col-span-1 min-w-40">
            {shopStore.filterOptions.map((item) => (
              <Filter
                lang={lang}
                item={item}
                key={item.type}
                filtersOnChanged={filtersOnChanged}
              />
            ))}
          </div>
        )}
        <div>
          <div className="text-end text-xl mb-2">
            <span className="font-light">{t("shop.coins")}: </span>
            {userStore.user?.coin || 0}
          </div>
          <div className="bg-white shadow-md col-span-5">
            <DataGrid
              rows={rows}
              columns={columns}
              limit={pageObj.limit}
              page={pageObj.page}
              rowCount={total}
              onSortModelChange={(model) => {
                setPageObj((prev) => ({
                  ...prev,
                  sort_by: model[0]?.field,
                  order_by: model[0]?.sort,
                }));
              }}
              onPaginationModelChange={(model) => {
                setPageObj((prev) => ({
                  ...prev,
                  page: model.page + 1,
                  limit: model.pageSize,
                }));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
