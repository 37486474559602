import { useState } from "react";
import { Button, TextField } from "@mui/material";
import http from "../../utils/axios";
import { CircularProgress } from "@mui/material";
import useToast from "../../utils/toast";
import { apiErrorMessage } from "../../utils/helpers";
import { MonetizationOnOutlined } from "@mui/icons-material";

const NewQuestion = ({ aiPayload, attempt, getAttempt, t }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [aiReply, setAiReply] = useState("");

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const payload = { ...aiPayload, more_questions: question };
      const aiRes = await http.post("/ai/comprehension", payload);
      setAiReply(aiRes.choices[0].message.content);

      const additional = [
        ...attempt.additional,
        { request: question, response: aiRes.choices[0].message.content },
      ];
      await http.put(`/attempt/${attempt._id}`, {
        additional,
      });
      getAttempt();
    } catch (error) {
      toast.error(apiErrorMessage(error) || "Unknown Error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 rounded-lg shadow-md bg-slate-100">
      <div className="flex-col flex gap-6">
        <div className="flex flex-col items-start gap-1">
          <label className="font-medium text-lg">
            {t("comp.fur-question")}
          </label>
          <TextField
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            disabled={!!aiReply}
            fullWidth
            multiline
            maxRows={5}
            type="text"
            size="small"
            sx={{
              "& .Mui-disabled": {
                WebkitTextFillColor: "black !important",
              },
            }}
          />
        </div>
        <div className="flex flex-col items-start gap-1">
          <label className="font-medium text-lg">{t("comp.ai-reply")}</label>
          <TextField
            value={aiReply || "..."}
            disabled
            fullWidth
            multiline
            maxRows={10}
            type="text"
            size="small"
            sx={{
              "& .Mui-disabled": {
                WebkitTextFillColor: "black !important",
              },
            }}
          />
        </div>
        {!aiReply && (
          <div className="flex flex-row justify-end gap-6">
            <Button
              variant="contained"
              type="button"
              className="relative"
              disabled={isLoading}
              onClick={() => {
                onSubmit();
              }}
            >
              <MonetizationOnOutlined />
              &nbsp;{t("comp.continue")}
              {isLoading && <CircularProgress className="absolute" size={20} />}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewQuestion;
