import { useState } from "react";
import { Button } from "@mui/material";
import NewQuestion from "./NewQuestion";
import KeyValueBox from "./KeyValueBox";
import { MonetizationOnOutlined } from "@mui/icons-material";

const newQuestionDefault = {
  question: "",
  answer: "",
};

const ResultBox = ({ item, aiPayload, attempt, getAttempt, t }) => {
  const [newQuestions, setNewQuestions] = useState([]);

  const getNewQuestion = () => {
    setNewQuestions((prev) => [newQuestionDefault]);
  };

  return (
    <div className="bg-white shadow-md p-5 rounded-md flex flex-col gap-6">
      {/* <KeyValueBox label={t("comp.question")} value={item.question} /> */}
      <KeyValueBox
        label={t("comp.your-ans")}
        label_key="comp.your-ans"
        value={item.answer}
        correct={item.correct}
      />
      <KeyValueBox
        label={t("comp.explanation")}
        label_key="comp.explanation"
        value={item.response}
      />
      <div className="text-end">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            getNewQuestion();
          }}
        >
          {t("comp.fur-question")}
        </Button>
      </div>
      <div className="flex flex-col gap-16">
        {newQuestions.map((item, index) => (
          <NewQuestion
            item={item}
            key={index}
            aiPayload={aiPayload}
            attempt={attempt}
            getAttempt={getAttempt}
            t={t}
          />
        ))}
      </div>
    </div>
  );
};

export default ResultBox;
