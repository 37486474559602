export const questionSecDefaultValue = {
  question: "",
  answer: "",
  level: "same",
};

export const questionSecLevels = [
  { en: "Easy", zht: "容易", zhs: "容易", value: 1 },
  { en: "Intermediate", zht: "中級", zhs: "中級", value: 2 },
  { en: "Advanced", zht: "難", zhs: "難", value: 3 },
];

export const paginationObj = {
  limit: 10,
  page: 1,
  sort_by: "updatedAt",
  order_by: "desc",
};

export const apiLocale = {
  en: "name",
  zht: "name_zt",
  zhs: "name_zs",
};
