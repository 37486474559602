import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getAccessToken,
  removeLocalStorage,
  apiErrorMessage,
} from "../../utils/helpers";
import { useUserStore } from "../../stores";
import http from "../../utils/axios";
import useToast from "../../utils/toast";
import { useTranslation } from "react-i18next";

const logo = require("../../images/logo.webp");

const Header = () => {
  const { t } = useTranslation("common");
  const toast = useToast();
  const navigate = useNavigate();
  const isLogin = !!getAccessToken();
  const userStore = useUserStore();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const navBar = [
    { name: t("header.reading"), path: "/exercise" },
    { name: t("header.grammar"), path: "/exercise/grammar" },
    { name: t("header.vocab"), path: "/exercise/vocabulary/generate" },
    { name: t("header.writing"), path: "inse" },
    { name: t("header.performance"), path: "/history" },
    { name: t("header.buy-exercise"), path: "/shop" },
  ];

  const settings = [
    {
      name: "Profile",
      path: "/profile",
      label: t("header.profile"),
    },
    {
      name: "My Cart",
      path: "/cart",
      label: t("header.my-cart"),
    },
    {
      name: "Logout",
      label: t("header.logout"),
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleRouting = (path) => {
    try {
      if (path === "inse") {
        redirectINSE();
      } else {
        handleCloseNavMenu();
        handleCloseUserMenu();
        navigate(path);
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const logout = () => {
    userStore.logout();
    removeLocalStorage();
    navigate("/login");
  };

  const redirectINSE = async () => {
    try {
      const res = await http.post("/inse");
      if (res?.message === "success") {
        navigate(`/inse/${res?.data?.authorization}`);
      } else {
        throw res;
      }
    } catch (error) {
      toast(apiErrorMessage(error));
      console.log(error);
    }
  };

  return (
    <AppBar position="static" className="bg-primary">
      <Container maxWidth="xl">
        <Toolbar>
          <div className="mr-2 hidden md:flex mt-1 mb-1">
            <img src={logo} alt="elite learning logo" height={90} />
          </div>
          {isLogin && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  keepMounted
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {navBar.map((page) => (
                    <MenuItem
                      key={page.name}
                      onClick={() => handleRouting(page.path)}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Typography
                variant="h5"
                noWrap
                href="#app-bar-with-responsive-menu"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                <img
                  src={logo}
                  alt="elite learning logo"
                  height={90}
                  className="mt-1 mb-1"
                />
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                {navBar.map((page) => (
                  <Button
                    key={page.name}
                    onClick={() => handleRouting(page.path)}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      textDecoration: "underline",
                      textUnderlineOffset: "4px",
                    }}
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar>
                      {userStore?.user?.name
                        ? userStore?.user?.name[0]?.toUpperCase()
                        : ""}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.name}
                      onClick={() => {
                        handleCloseUserMenu();
                        if (setting.name === "Logout") {
                          logout();
                        } else {
                          handleRouting(setting.path);
                        }
                      }}
                    >
                      <Typography textAlign="center">
                        {setting.label}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
