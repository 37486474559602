import { useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/common/Layout";

const INSE = () => {
  const { token } = useParams();

  const iframeRef = useRef(null);

  const handleResize = () => {
    // Set iframe height to match the available viewport height
    iframeRef.current.style.height = `${window.innerHeight}px`;
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // change iframe src
  useEffect(() => {
    iframeRef.current.src = `https://ai.inse.org/#/?platform=ext-ai&channel=web&locale=zh-tw&authorization=${token}`;
  }, [token]);

  return (
    <Layout fullWidth={true}>
      {token && (
        <iframe
          ref={iframeRef}
          title="INSE"
          className="w-full h-full"
          style={{ height: "100vh" }}
          // scrolling="no"
        />
      )}
    </Layout>
  );
};

export default INSE;
