const DisplayScore = ({ score, total, perc, label }) => {
  const percentage = Math.floor(perc);
  return (
    <div className="mb-6 text-end">
      {label}: {!isNaN(percentage) ? `${score}/${total} (${percentage}%)` : ""}
    </div>
  );
};

export default DisplayScore;
