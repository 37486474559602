import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { questionSecLevels } from "../../utils/definitions";
import { Controller } from "react-hook-form";

export default function LevelSelection({ name, methods, i18n }) {
  const { control } = methods;

  return (
    <FormControl>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            {...field}
          >
            {questionSecLevels.map((level) => (
              <FormControlLabel
                key={level.value}
                value={level.value}
                label={level[i18n.language]}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}
